<template>
  <div id="formrapport">
    <form @submit.prevent="submit">
      <v-row class="mt-10" align-self="end" align="center" no-gutters>
        <v-col cols="2" sm="2" class=" pl-4">
          <div class="text-left">DATE DU RAPPORT*</div>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{rapport.date_rapport}} </div>
          <v-menu v-if="mode != 'visualisation'"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            light
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-flex calendar-field">
                <v-text-field
                  v-model="formatDate"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  single-line
                  light
                  outlined
                  hide-details
                >
                  <template slot="append-outer">
                    <img class="ml-5" src="@/assets/calendar.png" />
                  </template>
                </v-text-field>
              </span>
            </template>
            <!-- message d'erreur -->

            <v-date-picker
              v-model="rapport.date_rapport"
              no-title
              locale="fr-fr"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <div
            class="errorMsg text-left red--text mb-3"
            v-if="$v.rapport.date_rapport.$error"
          >
            Renseignez la date du rapport
          </div>
        </v-col>

        <v-col cols="4" sm="4" class="pl-4 mt-1">
          <div class="text-left">CREATEUR</div>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{createur}} </div>

        <v-select
          v-if="mode !== 'visualisation'"
          :items="utilisateurs"
          item-value="id_utilisateur"
          item-text="nom"
          v-model="rapport.id_utilisateur"
          item-color='primary'
          class="text-color"
          hide-details
          solo
        ></v-select>

          <!-- <div class="errorMsg" v-if="$v.demandeDeGarantie.garantie.valeur.$error">
            Renseignez produit complet
          </div>
          <div v-if="mode == 'visualisation'" :class="garantieValue(demandeDeGarantie.garantie.valeur)">{{demandeDeGarantie.garantie.text}}</div> -->
        </v-col>


        <v-col cols="6" align-self="center">
          <div class="text-left ml-10">TYPE DE RAPPORT*</div>
          <div  v-if="mode == 'visualisation'" class="text-left ml-10 font-weight-bold">{{rapport.type_visite}} </div>
          <v-radio-group  v-if="mode != 'visualisation'"
            class="ml-10"
            row
            v-model="rapport.type_visite"
            hide-details
          >
            <v-radio label="APPEL" value="APPEL"></v-radio>
            <v-radio label="VISITE" value="VISITE"></v-radio>
          </v-radio-group>
          <div
            class="errorMsg text-left red--text mb-3 ml-10"
            v-if="$v.rapport.type_visite.$error"
          >
            Renseignez le type de rapport
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="5" class="d-flex pb-0 pl-16 mt-16">
          <div class="d-flex flex-column pr-16 ml-10">
            <div>APPEL</div>
            <v-checkbox
              color="info"
              label="OUI"
              value="true"
              v-model="rapport.rapport_appel"
            />
          </div>
          <div class="d-flex flex-column pl-16">
            <div>VISITE</div>
            <v-checkbox
              color="info"
              label="OUI"
              value="true"
              v-model="rapport.rapport_visite"
            />
          </div>
        </v-col> -->
      </v-row>

      <AddContacts :personnes="rapport.Personnes" :mode="mode" @emitPersonnes="test($event)"  ref="AddContacts" />
          <div
            class="errorMsg text-left pl-4 red--text"
            v-if="rapport.Personnes.length  == 0 && checkIfPersonne == true" 
          >
            Au moins une personne doit être rajouté
          </div>
      <!-------------------------------- BLOC DESCRIPTION -------------------------------->
      <div v-if="$store.state.entreprise.type_client !== 'REVENDEUR'">
              <v-col cols="12" sm="10" class="pb-0 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col cols="6" lg="6" xl="2" class="pa-0 font_color text-left">
            DESCRIPTION
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10">
        <div v-if="mode != 'visualisation'" class="text-left">DESCRIPTION*</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{rapport.description}} </div>
        <!-- message d'erreur -->
        <v-textarea v-if="mode != 'visualisation'" outlined v-model="rapport.description" hide-details>
        </v-textarea>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="$v.rapport.description.$error"
        >
          Renseignez la description du rapport
        </div>
        <!-- <div class="errorMsg" v-if="$v.description.$error && $v.description.required == false">
            Renseignez une description pour le projet.
          </div>

          <div class="errorMsg" v-if="$v.description.$error && ($v.description.required == true && $v.description.minLength == false) ">
            5 caractères au minimum.
          </div>

          <div class="errorMsg" v-if="$v.description.$error && ($v.description.required == true && $v.description.maxLength == false) ">
            500 caractères au maximum.
          </div> -->
        <!-- message d'erreur -->
      </v-col>
      </div>

      <AddDocuments :documents="rapport.documents" hide-details :mode="mode"  ref="AddDocuments" />
      <!-------------------------------- ACTION A REALISER------------------------------->
      <div>
      <div v-if="$store.state.entreprise.type_client !== 'REVENDEUR'">
              <v-col cols="12" sm="10" class="pb-0 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col cols="6" lg="6" xl="2" class="pa-0 font_color text-left">
            ACTION À REALISER
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10" class="py-0">
        <v-row align="center"  class="mt-5" no-gutters>
          <v-col cols="9">
            <div v-if="mode != 'visualisation'" class="text-left">ACTION À REALISER*</div>
            <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{rapport.action_a_realiser==true?"Oui":"Non"}} </div>
            <v-radio-group row v-model="rapport.action_a_realiser" v-if="mode != 'visualisation'">
              
              <v-radio label="OUI" value="true"></v-radio>
              <v-radio label="NON" value="false"></v-radio>
            </v-radio-group>
            <div
              class="errorMsg text-left red--text mb-3"
              v-if="$v.rapport.action_a_realiser.$error"
            >
              Indiquer si une action doit être réalisée
            </div>
          </v-col>
        </v-row>
      </v-col>
      </div>  


      <v-col cols="12" sm="10">
        <div v-if="$store.state.entreprise.type_client !== 'REVENDEUR'">
        <div class="text-left">COMMENTAIRE</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{rapport.commentaire_action}} </div>
        <v-textarea  v-if="mode != 'visualisation'"
          outlined
          auto-grow
          rows="4"
          v-model="rapport.commentaire_action"
        >
        
        </v-textarea>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="$v.rapport.commentaire_action.$error"
        >
          Le commentaire est obligatoire si une action doit être réalisée
        </div>
        </div>

      </v-col>
      </div>

      
      <AddRappel :rappels="rapport.Rappels" hide-details :mode="mode" ref="AddRappels" @closeRappel="closeRappel" />
      <RapportRevendeur v-if="$store.state.entreprise.type_client == 'REVENDEUR'" :rapport="rapport" hide-details :mode="mode" />
      <v-col cols="12" sm="10">
          <router-link v-if="mode === 'visualisation'" :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}">
        <v-btn @click="$store.state.currentTab = 2" class="btnBold"  color="#FF9900" dark rounded type="submit" >
          <div>
            RETOUR
          </div>
        </v-btn>
      </router-link>
           <router-link v-if="mode != 'visualisation'" :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}">
        <v-btn @click="$store.state.currentTab = 2" class="btnBold"  color="black" plain>
          <div>
            Annuler
          </div>
        </v-btn>
      </router-link>
          <v-btn v-if="mode !== 'visualisation'"
            class="btnBold"
            :elevation="0"
            color="#FF9900"
            dark
            rounded            
            @click="submit"
            :disabled="btnDisable"
          >
            <v-icon left> fa fa-plus </v-icon>
              ENREGISTRER
            <!-- <div  class="pl-5 pr-7">ENREGISTRER</div> -->
          </v-btn>
         
      </v-col>
    </form>
  </div>
</template>

<script>
import AddRappel from "./AddRappels";
import RapportRevendeur from "./RapportRevendeur";
import AddDocuments from "./AddDocuments";
import AddContacts from "./AddContacts";
import moment from "moment";
import {
  required,
  requiredIf,
  // minLength,
} from "vuelidate/lib/validators";
export default {
  components: {
    AddRappel,
    RapportRevendeur,
    AddDocuments,
    AddContacts,
  },
  data() {
    return {
      menu: false,
      loading: false,
      checkIfPersonne: false,
      personnes: [],
      rappels: [],
      documents: [],
      docs: [],
      
    };
  },
  props: ["rapport", "mode","btnDisable", "utilisateurs"],
  validations: {
    rapport: {
      date_rapport: { required },
      description:  {
        required: requiredIf(function () {
          return this.$store.state.entreprise.type_client !== 'REVENDEUR';
        }),
      },
      type_visite: { required },
      action_a_realiser:  {
        required: requiredIf(function () {
          return this.$store.state.entreprise.type_client !== 'REVENDEUR';
        }),
      },
      commentaire_action: {
        required: requiredIf(function () {
          return this.rapport.action_a_realiser == "true";
        }),
      },
    },
  },
   computed: {
    formatDate() {
      return this.rapport.date_rapport
        ? moment(this.rapport.date_rapport).format("L")
        : "";
    },
    createur() {
      var createur = this.utilisateurs.filter(obj => {
        return obj.id_utilisateur === this.rapport.id_utilisateur
      })
      createur = createur[0] != undefined ? createur[0].nom : 'non défini'
      return createur
    }
  },
  methods: {
    test($event) {
      console.log("Personnes", this.rapport)
      console.log("personnes", this.rapport.personnes)
      this.rapport.Personnes = $event
      
    },
    closeRappel(rappel) {
      console.log(rappel, "rappels")
      this.$emit("closeRappel",{id_rappel: rappel.id_rappel})
    },

    submit() {
      this.$v.$touch();
            // Scroll sur la première erreur de vuelidate
      if (this.$v.$invalid) {
         this.$nextTick(() => {
          let domRect = document.querySelector('.errorMsg');
          domRect.scrollIntoView(); 
        });
      }

      this.btnDisable = true
     
      if (!this.$v.$invalid && this.rapport.Personnes.length > 0) {
        // this.personnes = this.$refs.AddContacts.personnes;
        // this.rappels = this.$refs.AddRappels.rappels;
        // for (var i = 0; i < this.$refs.AddDocuments.documents.length; i++) {
        //   this.docs.push({
        //     files: this.$refs.AddDocuments.documents[i].files,
        //   });
        // }


        this.rapport.Personnes = this.$refs.AddContacts.personnes;

      this.rapport.rappels = this.$refs.AddRappels.rappels;

      for (var i = 0; i < this.$refs.AddDocuments.documents.length; i++) {
        console.log(this.$refs.AddDocuments.documents[i])
         if (this.$refs.AddDocuments.documents[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocuments.documents[i].files,
          });
        }
      }
      console.log(this.rapport,this.docs);

        let obj = {
          rapport: {
            id_rapport:this.rapport.id_rapport,
            date_rapport: this.rapport.date_rapport,
            description: this.rapport.description,
            appel: this.rapport.type_visite == 'APPEL'?'1':'0',
            visite: this.rapport.type_visite=='VISITE'?'1':'0',
            action_a_realiser: this.rapport.action_a_realiser,
            commentaire_action: this.rapport.commentaire_action,
            id_utilisateur: this.rapport.id_utilisateur,
            infos_revendeur: this.rapport.infos_revendeur,
            id_entreprise: this.$route.params.id_entreprise,
          },
          personnes: this.rapport.Personnes,
          rappels: this.rapport.rappels,
        };
        console.log("helloooo", obj, this.rapport_appel, this.rapport_visite,this.docs);

        //Document
        // let data = new FormData();
        // data.append("mode", "rapports");
        // for (var j = 0; j < this.docs.length; j++) {
        //   // if (this.docs[j].id_document === undefined) {
        //     let file = this.docs[j].files;
        //     data.append("documents", file);
            
        //   }
        // }
        // console.log(data, "ddddddddddatta");
        var config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };
// console.log("before emit", obj);
        this.$emit("rapport", {
          rapport: obj,
          documents: this.docs,
          config: config,
        });
      } else {
        if(this.rapport.Personnes.length == 0){
          this.checkIfPersonne = true;
        }
      }
      this.btnDisable = false
    },
    // validationForm() {
    //   this.$v.$touch();
    //   if (!this.$v.$invalid) {
    //     this.$emit("rapport", this.rapport);
    //   }
    // },
  },
};
</script> 

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
#formrapport {
  .rectangle_bleu {
    background-color: $background-color-blue;
    color: white;
    padding: 1% 0 1% 2%;
    text-align: left;
    font-weight: bold;
    margin-top: 5%;
  }

  // ::v-deep .v-input--radio-group.v-input--radio-group--row {
  //   margin-top: 0;
  // }
  #hidden {
    color: #fff;
  }
  ::v-deep .v-radio > .v-label {
    font-weight: bold;
    font-size: 1.2em;
  }
  .btnBold {
    font-weight: bold;
  }
  .font_color {
    font-weight: bold;
    color: #ffffff;
  }
  .v-input--selection-controls {
    margin-top: 0;
  }
}
img {
  width: 29px;
  height: 28px;
}



.card_border.v-card.v-sheet.theme--light {
	border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}


.font_color {
  font-weight: bold;
  color: #FFFFFF 
}



</style>
